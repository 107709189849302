<template>
  <section class="address-modal">
    <form
      @submit.prevent="handleSubmit"
      @invalid.capture.prevent="validate"
    >
      <BaseModal
        size="medium"
        :show-modal="showModal"
      >
        <template #body>
          <div class="c-addressForm">
            <Accordion>
              <AccordionItem
                :title="$t(`widgets.profileAddress.form.${selectedAddress.id
                  ? 'editAddressTitle' : 'addAddressTitle'}`)"
                icon="location"
                keep-open
              >
                <template #accordion-content>
                  <div class="-gridBox4-8">
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.name.label')"
                      required="true"
                      :placeholder="$t('widgets.profileAddress.form.name.placeholder')"
                      name="name"
                      id="name"
                      :ref="el => { inputEl['name'] = el }"
                      type="text"
                      v-model="forms.name"
                    />
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.address.label')"
                      required="true"
                      :placeholder="$t('widgets.profileAddress.form.address.placeholder')"
                      name="address"
                      id="address"
                      :ref="el => { inputEl['address'] = el }"
                      type="text"
                      v-model="forms.address"
                    />
                  </div>
                  <div class="-gridBox3">
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.city.label')"
                      required="true"
                      :placeholder="$t('widgets.profileAddress.form.city.placeholder')"
                      name="city"
                      id="city"
                      :ref="el => { inputEl['city'] = el }"
                      type="text"
                      v-model="forms.city"
                    />
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.province.label')"
                      required="true"
                      :placeholder="$t('widgets.profileAddress.form.province.placeholder')"
                      name="province"
                      id="province"
                      :ref="el => { inputEl['province'] = el }"
                      type="text"
                      v-model="forms.province"
                    />
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.zipcode.label')"
                      required="true"
                      :placeholder="$t('widgets.profileAddress.form.zipcode.placeholder')"
                      name="zipcode"
                      id="zipcode"
                      :ref="el => { inputEl['zipcode'] = el }"
                      type="text"
                      v-model="forms.zipcode"
                    />
                  </div>
                  <div class="-gridBox4-8">
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.doorphone.label')"
                      :placeholder="$t('widgets.profileAddress.form.doorphone.placeholder')"
                      name="doorphone"
                      id="doorphone"
                      :ref="el => { inputEl['doorphone'] = el }"
                      type="text"
                      v-model="forms.doorphone"
                    />
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.floor.label')"
                      :placeholder="$t('widgets.profileAddress.form.floor.placeholder')"
                      name="floor"
                      id="floor"
                      :ref="el => { inputEl['floor'] = el }"
                      type="text"
                      v-model="forms.floor"
                    />
                  </div>

                  <Checkbox
                    v-if="isAuthenticated"
                    :text="$t('widgets.profileAddress.form.defaultBillingLabel')"
                    v-model="forms.default_billing"
                    :checked="forms.default_billing"
                    :disabled="addressIsInvalidForBilling"
                    :true-value="1"
                    :false-value="0"
                  />
                  <Checkbox
                    v-if="isAuthenticated"
                    :text="$t('widgets.profileAddress.form.defaultDeliveryLabel')"
                    v-model="forms.default_delivery"
                    :checked="forms.default_delivery"
                    :true-value="1"
                    :false-value="0"
                  />
                </template>
              </AccordionItem>
            </Accordion>
            <div class="h-pb-20" />
            <Accordion v-if="isAuthenticated">
              <AccordionItem
                :title="$t('widgets.profileAddress.form.billingAddressTitle')"
                icon="credit-card"
              >
                <template #accordion-content>
                  <div class="-gridBox">
                    <BaseRadio
                      :text="$t('widgets.profileAddress.form.billing_private[0]')"
                      :value="0"
                      name="billing_private"
                      id="radio-company"
                      :checked="forms.billing_private === 0"
                      v-model="forms.billing_private"
                    />
                    <BaseRadio
                      :text="$t('widgets.profileAddress.form.billing_private[1]')"
                      :value="1"
                      name="billing_private"
                      id="radio-private"
                      :checked="forms.billing_private === 1"
                      v-model="forms.billing_private"
                    />
                  </div>
                  <BaseTextBox
                    :label="$t('widgets.profileAddress.form.company.label')"
                    :placeholder="$t('widgets.profileAddress.form.company.placeholder')"
                    name="company"
                    id="company"
                    :ref="el => { inputEl['company'] = el }"
                    type="text"
                    v-model="forms.company"
                  />
                  <div class="-gridBox">
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.vat.label')"
                      :placeholder="$t('widgets.profileAddress.form.vat.placeholder')"
                      name="vat"
                      id="vat"
                      :ref="el => { inputEl['vat'] = el }"
                      type="text"
                      v-model="forms.vat"
                    />
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.sdi.label')"
                      :placeholder="$t('widgets.profileAddress.form.sdi.placeholder')"
                      name="sdi"
                      id="sdi"
                      :ref="el => { inputEl['sdi'] = el }"
                      type="text"
                      v-model="forms.sdi"
                    />
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.pec.label')"
                      :placeholder="$t('widgets.profileAddress.form.pec.placeholder')"
                      name="pec"
                      id="pec"
                      :ref="el => { inputEl['pec'] = el }"
                      type="text"
                      v-model="forms.pec"
                    />
                    <BaseTextBox
                      :label="$t('widgets.profileAddress.form.taxcode.label')"
                      :placeholder="$t('widgets.profileAddress.form.taxcode.placeholder')"
                      name="taxcode"
                      id="taxcode"
                      :ref="el => { inputEl['taxcode'] = el }"
                      type="text"
                      v-model="forms.taxcode"
                    />
                  </div>
                  <BaseSelect
                    id="country"
                    :label="$t('widgets.profileAddress.form.country.placeholder')"
                    :options="countriesList"
                    v-model="forms.country"
                    name="select-time"
                  />
                </template>
              </AccordionItem>
            </Accordion>
          </div>
        </template>
        <template #footer>
          <BaseButton
            variant="solid"
            color="primary"
            :display-loader="showLoader"
            is-full-width
            :btn-label="$t(`common.ctaLabels.${selectedAddress.id ? 'save' : 'submit'}`)"
            type="submit"
          />
        </template>
      </BaseModal>
    </form>
  </section>
</template>

<script setup >
import _omit from 'lodash.omit';
import {
  computed, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

import { apiServices } from '@/api/services';
import Accordion from '@/components/common/Accordion/Accordion.vue';
import AccordionItem from '@/components/common/Accordion/AccordionItem.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseModal from '@/components/common/BaseModal.vue';
import BaseRadio from '@/components/common/BaseRadio.vue';
import BaseSelect from '@/components/common/BaseSelect.vue';
import BaseTextBox from '@/components/common/BaseTextBox.vue';
import Checkbox from '@/components/common/Checkbox.vue';

const store = useStore();
const showModal = computed(() => store.state.overlays.openModal === 'AddressModal');
const showLoader = computed(() => store.state.loaders.showLoader);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const selectedAddress = computed(() => store.state.auth.selectedAddressForEdit);
const inputEl = ref([]);

const countriesList = ref([]);
// TODO: Find a way to avoid calling this api many times, maybe at Home.vue?

async function getCountriesList() {
  if (isAuthenticated.value) {
    countriesList.value = await apiServices.getCountries()
      .then(res => res.map(d => ({ label: d, value: d, selected: d === 'IT' })));
  }
}

getCountriesList();

const setFormValues = () => ({
  id: selectedAddress.value.id ?? null,
  name: selectedAddress.value.name ?? '',
  address: selectedAddress.value.address ?? '',
  city: selectedAddress.value.city ?? '',
  province: selectedAddress.value.province ?? '',
  zipcode: selectedAddress.value.zipcode ?? '',
  floor: selectedAddress.value.floor ?? null,
  doorphone: selectedAddress.value.doorphone ?? null,
  company: selectedAddress.value.company ?? null,
  vat: selectedAddress.value.vat ?? null,
  taxcode: selectedAddress.value.taxcode ?? null,
  sdi: selectedAddress.value.sdi ?? null,
  pec: selectedAddress.value.pec ?? null,
  country: selectedAddress.value.country ?? 'IT',
  billing_private: selectedAddress.value.billing_private ?? 0,
  default_billing: selectedAddress.value.default_billing ?? 0,
  default_delivery: selectedAddress.value.default_delivery ?? 0,
  selectedForDelivery: selectedAddress.value.selectedForDelivery ?? false,
  selectedForBilling: selectedAddress.value.selectedForBilling ?? false,
});

const forms = ref(setFormValues());
const addressIsInvalidForBilling = computed(
  () => (Number(forms.value.billing_private) === 0
      && (!forms.value.company || !forms.value.vat || !forms.value.sdi || !forms.value.taxcode))
      || (Number(forms.value.billing_private) === 1
          && (!forms.value.company || !forms.value.taxcode)
      ),
);
const validate = evt => {
  inputEl.value[evt.target.id].validateForm(evt);
};

const handleSubmit = () => {
  const formData = _omit(forms.value, [ 'selectedForDelivery', 'selectedForBilling' ]);

  if (selectedAddress.value.id) {
    store.dispatch(`auth/${isAuthenticated.value
      ? 'updateCustomerAddress' : 'updateTempCustomerAddresses'}`,
    { data: formData, id: selectedAddress.value.id });
  } else {
    store.dispatch('auth/addCustomerAddress', formData);
  }
};

watch(() => showModal.value, () => {
  if (showModal.value) forms.value = setFormValues();
});
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-addressForm {
  width: 100%;

  .-gridBox {
    display: grid;
    grid-template-columns: 1fr;

    @include mx.bp("desktop") {
      grid-gap: 0 16px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .-gridBox4-8 {
    @include mx.bp("desktop") {
      display: grid;
      grid-gap: 0 16px;
      grid-template-columns: 1fr 2fr;
    }
  }

  .-gridBox3 {
    @include mx.bp("desktop") {
      display: grid;
      grid-gap: 0 16px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &::v-deep('.c-checkbox') {
    &.c-checkbox__input:disabled {
      opacity: 0.5;
    }
  }
}
</style>
